import React, { useEffect, useState } from 'react'
import Container from '../../components/Container/Container'
import SearchBar from '../../components/SearchBar/SearchBar';
import styles from './Search.module.scss'
import User from '../../components/User/User'
import ArticleApi from '../../api/article';
import ArticleResult from '../../components/ArticleResult/ArticleResult'
import { Helmet } from "react-helmet"

export default function Search(props) {

  const urlParams = new URLSearchParams(props.location.search);
  const SearchParam = urlParams.get('q');
  const q = SearchParam?.split(' ').join('+') ?? "";

  const [results, setResults] = useState([])

  useEffect(() => {
    ArticleApi.search(q, data => setResults(data))
  }, [])

  return (
    <Container margin="true" size="large">
      <div className={styles.top}>
        <SearchBar />
        <User />
      </div>
      <p className={styles.resulttext}>Results for "{SearchParam}" </p>
      {results.map(result =>
        <ArticleResult
          key={result._id}
          name={result.title}
          text={result.snipped}
          id={result._id}
          date={result.date} />
      )}
    </Container>
  )
}

//bestes search ergebnis 111.10000000000001

