import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Container from '../../components/Container/Container'
import SearchBar from '../../components/SearchBar/SearchBar';
import styles from './UserSettings.module.scss'
import ArticleApi from '../../api/article';
import UserApi from '../../api/user';
import Button from '../../components/Button/Button'
import ArticleResult from '../../components/ArticleResult/ArticleResult'
import Icon from '../../components/Icon/Icon'
import Dialog from '../../components/Dialog/Dialog'
import User from '../../components/User/User'
import { useLocalStorage } from '../../hooks/useStorage/useStorage';
import Input from '../../components/Input/input'
import Overlay from '../../components/Overlay/Overlay';

export default function UserSettings(props) {
  return (
    <Container margin="true" size="large">
      <Header />
      <Settings />
      <Articles/>
    </Container>
  )
}

function Header() {
  return (
    <div className={styles.top}>
      <h1 className={styles.title}>User Settings</h1>
      <SearchBar />
      <User />
    </div>
  )
}

function Settings() {
  const [user] = useLocalStorage("session.user")
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [oldPassword, setOldPassword] = useState("")
  const [retypedOldPassword, setRetypedOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [error, setError] = useState("")

  function closeForm() {
    setDialogOpen(false)
    setOldPassword("")
    setRetypedOldPassword("")
    setNewPassword("")
    setError("")
  }

  async function handleChangePassword(value) {
    if (!value) return closeForm()
    if (oldPassword !== retypedOldPassword) return setError("Old passwords do not match")
    setLoading(true)
    const result = await UserApi.changePassword(oldPassword, newPassword)
    setLoading(false)
    if(!result) return setError("Old password is incorrect")
    closeForm()
  }

  return (
    <div className={styles.userWrapper}>
      <User size="large" hidePopup={true} />
      <div className={styles.userText}>
        <h1 className={styles.title}>{user.username}</h1>
        <p className={styles.email}>{user.email}</p>
        <Button
          onClick={() => setDialogOpen(true)}
          type="outline"
        >Change Password</Button>
        <Overlay
          show={loading}
        />
        <Dialog
          show={dialogOpen}
          title="Change Password"
          cancel
          onClick={handleChangePassword}
          error={error}
        >
          <Input
            placeholder="old password"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <Input
            placeholder="retype old password"
            type="password"
            value={retypedOldPassword}
            onChange={(e) => setRetypedOldPassword(e.target.value)}
          />
          <Input
            placeholder="new password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Dialog>
      </div>
    </div>
  )
}

function Articles() {
  const [results, setResults] = useState([])

  useEffect(() => {
    ArticleApi.getUserArticles(data => setResults(data))
  }, [])

  return (
    <>
      <h1 className={styles.articles}>My Articles
        <Link className={styles.edit} to={"/article/new/edit"}>
          <Icon name="fas fa-plus" />
        </Link>
      </h1>
      {
        results.map(result =>
          <ArticleResult
            key={result._id}
            name={result.title}
            text={result.snipped}
            id={result._id}
            date={result.date} />
        )
      }
    </>
  )
}
